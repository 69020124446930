import React from 'react'
import { Link } from 'gatsby'
import "../scss/NavigationBar.scss"
import { captureEvent } from 'event-service/modules/capture'
import SearchBox from './searchBox/SearchBox'

function NavigationBar(props) {
    const { siteNav } = props;
    const halfIndex = Math.ceil(siteNav.menu.length / 2);
    const leftSideMenu = siteNav.menu.slice(0, halfIndex);
    const rightSideMenu = siteNav.menu.slice(halfIndex);

    const renderMenu = (menuItems) => menuItems.map((item, i) => {

        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code
            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) { // goes to the lowest level to check for drop downs within drop downs

                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return lowItem.link.linkPath.length > 0 ? (
                            <Link id='nav_link' key={lowItem.link.linkName} className='nav-smallDrop' to={lowItem.link.linkPath}>
                                <span>{lowItem.link.linkName}</span>
                            </Link>
                        ) : (
                                <p key={lowItem.link.linkName} className='nav-smallDrop'>
                                    <span>{lowItem.link.linkName}</span>
                                </p>
                            )
                    })

                    return (
                        <li className="nav-li" key={x}>
                            <div className="button button_color-white button_font-small menu__button js-button">
                                <span className="button__subtitle js-button-subtitle dropHover">{subItem.link.linkName}</span>
                            </div>
                            <div className="nav-sub">
                                {lowestMenuLevel}
                            </div>
                        </li>
                    )
                } else {
                    return (
                        <li key={subItem.link.linkName}>
                            <Link id='nav_link' className="button button_color-white button_font-small menu__button js-button dropHover" to={subItem.link.linkPath}>
                                <span className="button__subtitle js-button-subtitle">{subItem.link.linkName}</span>
                            </Link>
                        </li>
                    )
                }
            })

            return item.link.linkPath.length > 0 ? (
                <div className="header__item js-header-item dropdown dropdown-6">
                    <Link id='nav_link' key={i} to={item.link.linkPath} className="">
                        <span className="button button_color-default button_font-small menu__button">
                            <span className="button__subtitle js-button-subtitle">{item.link.linkName} 
                                <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8">
                                    <g><g>
                                        <path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path>
                                    </g></g>
                                </svg>
                            </span>
                        </span>
                    </Link>
                    <ul id={item.link.linkName} className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                        {subMenu}
                    </ul>
                </div>
            ) : (
                    <p key={i} className="header__item js-header-item dropdown dropdown-6">
                        <span className="button button_color-default button_font-small menu__button">
                            <span className="button__subtitle js-button-subtitle">{item.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                        </span>
                        <ul id={item.link.linkName} className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                            {subMenu}
                        </ul>
                    </p>
                )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link id='nav_link' to={item.link.linkPath} key={item.link.linkName} className="header__item js-header-item " onClick={() => toggleMobile()}>
                    <span className="button button_color-default button_font-small menu__button js-button">
                        <span className="button__subtitle js-button-subtitle">{item.link.linkName}</span>
                    </span>
                </Link>
            ) : (
                <p key={item.link.linkName} className="header__item js-header-item " onClick={() => toggleMobile()}>
                    <span className="button button_color-default button_font-small menu__button js-button">
                        <span className="button__subtitle js-button-subtitle">{item.link.linkName}</span>
                    </span>
                </p>
            )
        }
    })

    let mobileMenu = siteNav.menu.map((item, i) => {
        if (item.children && item.children.length > 0) {
            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) {
                    // Handle sub-sub-menus
                    let lowestMenuLevel = subItem.children.map((lowItem, y) => {
                        return lowItem.link.linkPath.length > 0 ?
                            (
                                <Link id='nav_link' key={lowItem.link.linkName + y} className="nav-link nav-drop" to={lowItem.link.linkPath}>
                                    <span className="nav-span nav-small">{lowItem.link.linkName}</span>
                                </Link>
                            ) : (
                                <p key={lowItem.link.linkName + y} className="nav-link nav-drop">
                                    <span className="nav-span nav-small">{lowItem.link.linkName}</span>
                                </p>
                            )
                    });
    
                    return (
                        <div key={subItem.link.linkName + x} className="nav-link nav-drop nav-secondDrop">
                            <span onClick={() => toggleDrop(subItem.link.linkName + '-mobile')} className="nav-span">{subItem.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                            <div className="nav-dropInfo" id={subItem.link.linkName + '-mobile'}>
                                {lowestMenuLevel}
                            </div>
                        </div>
                    )
                } else {
                    // Handle regular sub-menus
                    return subItem.link.linkPath.length > 0 ?
                        (
                            <Link id='nav_link' key={subItem.link.linkName + x} className="nav-link nav-drop" to={subItem.link.linkPath}>
                                <span className="nav-span nav-small">{subItem.link.linkName}</span>
                            </Link>
                        ) : (
                            <p key={subItem.link.linkName + x} className="nav-link nav-drop">
                                <span className="nav-span nav-small">{subItem.link.linkName}</span>
                            </p>
                        )
                }
            });
    
            return (
                <div key={item.link.linkName + i} className="nav-link">
                    <span onClick={() => toggleDrop(item.link.linkName + '-mobile')} className="nav-span">{item.link.linkName} <svg className="nav-svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><g><g><path fill="none" stroke="currentColor" stroke-miterlimit="20" stroke-width="2" d="M1 1v0l5 5v0l5-5v0"></path></g></g></svg></span>
                    <div className="nav-dropInfo" id={item.link.linkName + '-mobile'}>
                        {subMenu}
                    </div>
                </div>
            )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link id='nav_link' key={item.link.linkName + i} className="nav-link" to={item.link.linkPath} onClick={() => toggleMobile()}>
                    <span className="nav-span">{item.link.linkName}</span>
                </Link>
            ) : (
                <p key={item.link.linkName + i} className="nav-link" onClick={() => toggleMobile()}>
                    <span className="nav-span">{item.link.linkName}</span>
                </p>
            )
        }
    });    
    const unifiedMenu = renderMenu(siteNav.menu);
    return (
        <header id="header">
            <div className="header site__section js-header">
                <a onClick={() => toggleMobile()} className="burger header__explore-menu js-header-explore-button">
                    <span className="collapsible-menu" alt="hamburger icon for expandable menu"/>
                </a>

                <div className='topNav'>
                     <div className="header_nav">
                        {unifiedMenu}
                        <SearchBox />
                     </div>
                    
                    <section className="header__section site__subsection">
                        <Link id='nav_link' className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
                            <img className="nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
                        </Link>
                    </section>
                    <div className="mobileSearch">
                        <SearchBox />
                    </div>
               </div>
               

            </div>
            <section className='nav-mobile'>
            <div id="mobileDropDown" className="nav-cont">
                        {/* {unifiedMenu}  */}
                        <div className="nav-overflow">
                            {mobileMenu}
                        </div>
                </div>
            </section>
        </header>
    )
}

function toggleDrop(id) {
    const div = document.getElementById(id);
    div.classList.toggle('showDrop');
}

function toggleMobile() {
    document.getElementById("mobileDropDown").classList.toggle('showMobile');
    document.getElementsByClassName("header__explore-menu")[0].classList.toggle('state_toggled');
}

export default NavigationBar;